import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../table/CustomTable";
import CustomWrapper from "../../wrapper/CustomWrapper";
import SVGProgress from "../../svg_progress/SVGProgress";
import "./PaymentDashboard.css";
import Graph from "../../Graph/Graph";
import { getAllTrancationsForPropertyManager, getPaymentMethodForUser, pmTransactionInsite } from "../../../services/payment.method.service";
import { isLoggedIn } from "../../../utility/util";
import { Link } from "react-router-dom";

import SidePopupWrapper from "../../wrapper/SidePopupWrapper";
import { MdClose } from "react-icons/md";
import { getAllProperties, getDuesForPM } from "../../../services/property.service";
import { Loader, MiniLoader } from "../../Loader/Loader";

function PaymentDashboard({ userData = null, graphDuration=6, onNoPaymentAccountFound = null}) {
  const [isLoading, setLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [isOutstandingPayments, setIsOutstandingPayments] = useState(false);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [customTableData, setCustomTableData] = useState([]);
  const [graphDetails, setGraphDetails] = useState(null);
  const [balanceRenters, setBalanceRenter] = useState([]);
  const [totalRenters, setTotalRenters] = useState(0);
  const [totalProperties, setTotalProperties] = useState(0);
  const [currentMonthOntime, setCurrentMonthOntime] = useState(0);

  useEffect(() => {
    document.title = "Payment Details | Property Manager | Ando";
    let uInfo = isLoggedIn();

    let currentDate = new Date();
    let renterConfirmedDate = new Date();
    let totalRentFromStart = 0;
    let totalRentPerMonthExpected = 0;
    let tempExpectedRent = 0;
    let insiteData = null;

    if (userData == null) {
      userData = uInfo;
    }
    setLoading(true);
    getDuesForPM(uInfo?.data?.id)
    .then((resp) => {
      if(resp?.length > 0){
        let allProperties = [];
        let balanceRenters = [];
        let totalPendingRent = 0;
        resp?.map((renter, j) => {
          renter?.map((item, i) => {
            if(!allProperties?.includes(item?.address))
              allProperties.push(item?.address)
            let currentDate = new Date();
            let date = new Date(`${item?.year}-${String(item?.month).padStart(2,"0")}-01`)
            balanceRenters.push(
              <BalanceRenter
                date={date.toLocaleString("default", { month: "long" }) + " " +date.getFullYear()}
                tenant={item?.renterName}
                tenantEmail={item?.renterEmail}
                address={item.address}
                unit={item.propertyName}
                amount={item?.rent + ((i == 0) && (currentDate?.getDate() < 16) ? 0 : item?.penalty)}
                key={`${j}_${i}`}
              />)
              totalPendingRent += (item?.rent + ((i == 0) && (currentDate?.getDate() < 16) ? 0 : item?.penalty))
          })
        })
        setLoading(false);
        setPendingAmount(totalPendingRent)
        setTotalProperties(allProperties?.length);
        setTotalRenters(resp?.length)
        setBalanceRenter(balanceRenters)
      }
    })
    setLoading(true);
    getPaymentMethodForUser(uInfo?.data?.id).then((resp) => {
      pmTransactionInsite(uInfo.data.id)
      .then((resp) => {
        setLoading(false);
        if(resp?.status){
          let expectedOntime = resp?.data?.ontimePayments?.totalMonthlyExpectedTransaction;
          tempExpectedRent = resp?.data?.perMonthExpectedRent;
          let currentMonthOnTime = parseInt(resp?.data?.ontimePayments?.data[0]?.total_transaction ?? 0)
          let res = expectedOntime == 0 && currentMonthOnTime != 0 ? 100 : Math.round((currentMonthOnTime / parseInt(expectedOntime)) * 100)
          setCurrentMonthOntime(res ? res !== "NaN" ? res : 0 : 0 ?? 0)
          insiteData = resp;
        }
      })
      setLoading(true);
        getAllProperties(uInfo?.data?.id)
        .then((properties) => {
          setLoading(false);
          if(properties.length > 0){
            let renters = 0;
            properties.forEach((property) => {
              property.units.forEach((unit) => {
                if(unit.renterDetails.confirmed){                  
                  renters++;
                  renterConfirmedDate = new Date(unit.renterConfirmedDate);
                  let diffDate = currentDate - renterConfirmedDate;
                  let totalMonths = Math.floor(diffDate / 1000 / 60 / 60 / 24 / 30) + 2;
                  totalRentFromStart += (parseInt(unit.rentalPrice) * totalMonths);
                  totalRentPerMonthExpected += parseInt(unit.rentalPrice);
                }
              })
            })
          }
        })
        .then(() => {
          let du = graphDuration==="current" ? 6 : graphDuration
          setLoading(true);
          getAllTrancationsForPropertyManager(uInfo?.data?.id, du).then((transactions) => {
            setLoading(false);
            if(!resp?.status && !transactions.status && totalProperties == 0 ){
              onNoPaymentAccountFound();

              setGraphDetails("");
            }
            else if(!transactions.status){
              // setNoDataFound(true);
              
              setGraphDetails("");
            }
            else{
              let graphData = [];
              let onTimeTransactions = 0;
              let customData = [];
              let balance = 0;
              let totalRentPaid = 0;
              transactions.data?.forEach((element) => {
                balance+=element.transactionDetails.balance;
                totalRentPaid += parseInt(element.amount);
                let paymentDate = new Date(element.created_at);
                if(element.transactionDetails.balance <= 0){
                  onTimeTransactions++;
                }
                let rentFor = new Date(element?.transactionDetails?.rentPeriod)
                rentFor.setDate(15)
                let paymentStatus =  paymentDate > rentFor  ? "Late" : "On Time";
                let graphMonth = paymentDate.toLocaleString("default", { month: "long" });
                let graphYear = paymentDate.getFullYear();
                let graphAmount = parseInt(element.amount);
                let currentDataIndex = graphData.findIndex((e) => e?.month === graphMonth && e?.year === graphYear)
                let insite = insiteData?.data?.rentForPastMonths?.find((e) => {
                  let tempDate = new Date(e?.month_start);
                  return tempDate?.getFullYear() == graphYear && tempDate?.toLocaleString("default", { month: "long" }) == graphMonth
                })
                if(currentDataIndex !== -1){
                  graphData[currentDataIndex].paymentReceived += graphAmount
                }
                else{
                  graphData.push({
                    month : graphMonth,
                    year : graphYear,
                    paymentReceived : graphAmount,
                    expectedRent : parseInt(insite?.expectedRent?? 0) ?? 0
                  })
                }

                if(!element?.id?.toString().includes(".")){
                  let data = {
                    "Property/Unit" : <div className="tablePropertyColumn">
                                        {element.transactionDetails.property_details.address}
                                        <p>#{element.transactionDetails.property_details.unit}</p>
                                      </div>,
                    "Tenant" : <div className="tableRenterColumn">
                                {element.transactionDetails.renter.name}
                                <p>{element.transactionDetails.renter.email}</p>
                              </div>,
                    "Amount" : "$" + element.amount,
                    "Status" : paymentStatus,
                    "Balance" : "$" + element.transactionDetails.balance,
                    "Payment Date" : `${paymentDate.getDate()} ${paymentDate.toLocaleString("default", { month: "long" })}, ${paymentDate.getFullYear()}`,
                    "Rent Period" : `${element.transactionDetails?.rentPeriod}`,
                  }
                  customData.push(data)
                }
              })
              
              setGraphDetails(...[{
                dataName : "Rent Payments",
                total : totalRentPaid,
                max_monthly_payment_expected : totalRentPerMonthExpected == 0 ? parseInt(tempExpectedRent) : totalRentPerMonthExpected,
                data : graphData
              }])
              setCustomTableData([...customData]);
            }
          })
          .catch((e)=> {
            setGraphDetails("");
          })
        })
    });
  }, [graphDuration]);
  const performPoPup = (called) => {
    setIsOutstandingPayments(called);
  };
  const onClose=()=>{
    setIsOutstandingPayments(false);
  }

  if (noDataFound) {
    return (
      <CustomWrapper
        width="auto"
        maxWidth={"auto"}
        height="100%"
        marginHorizontal="50px"
        marginVertical="10px"
        paddingHorizontal={30}
        paddingVertical={30}
      >
        <div className="paymentOptionContainer">
          <div className="manageAccountHeader">
            <div className="title">Payment Transactions</div>
          </div>
          <div className="renterDescription">
            Come back here for a detailed transaction log of all rent payments
            once you've successfully collected your first one
          </div>
        </div>
      </CustomWrapper>
    );
  } else {
    return (
      <div style={{
        height : '100%',
        paddingBottom : 80
      }}>
      <CustomWrapper
        width="auto"
        maxWidth={"auto"}
        height="100%"
        marginHorizontal="50px"
        marginVertical="10px"
        paddingHorizontal={30}
        paddingVertical={30}
        background="transparent"
        border="none"
      >
        <div className="paymentDashboard">
          <div className="topDivContainer">
            <div className="leftDiv">
              <div className="subDash subRow">
                <div className="infoDiv">
                  <div className="subDashTitle">On-Time Payments</div>
                  <div className="subDashDesc">
                    Percentages of payments made before or by the expected rent
                    due date
                  </div>
                </div>
                <SVGProgress _percentage={currentMonthOntime} />
              </div>
              <div className="subDash subColumn">
                <div className="infoDiv">
                  <div className="subDashTitle">Outstanding Payments</div>
                  <div className="subDashDesc">Rental payments still owed</div>
                </div>
                <div className="pendingRentHint">{ isLoading ? <MiniLoader /> : `$${pendingAmount}` }</div>
                <div className="viewOutstanding">
                  <Link onClick={performPoPup}> view Details &gt; </Link>
                </div>
              </div>
            </div>
            <div className="rightDiv">
              {
                graphDetails != null && graphDetails != "" &&
                <Graph json={graphDetails} graphDuration={graphDuration} />
              }
              {
                graphDetails == null &&
                <div className='graphContainer' style={{
                  height: '100%',
                  background: "#FFFFFF",
                  borderRadius: 10,
                  display : "flex",
                  alignItems : 'center',
                  justifyContent : 'center'
                }}><MiniLoader /> </div>
              }
            </div>
          </div>
          <CustomTable title={"Payment Transactions"} json={customTableData} />
        </div>
      </CustomWrapper>
      {isOutstandingPayments && (
          <SidePopupWrapper>
            <div className="paymentHeader">
              <MdClose
                onClick={onClose}
                color="white"
                size={20}
                className="closePopup "
              />
              <label>Outstanding Payments</label>
            </div>
            <div className="paymentBody" style={{flex : 1, overflow: "hidden", display: "flex", flexDirection: "column"}}>
              <div className="owe-container">
                <div className="bodyTitle"> You’re owed</div>
                <div className="pendingRentHint owe-amount">${pendingAmount}</div>
              </div>
              <div className="detailsContainer">
                <div className="details">
                  <div className="countTenants">{totalRenters}</div>
                  <div className="tenants">tenants</div>
                </div>
                <div className="details">
                  <div className="countTenants">{totalProperties}</div>
                  <div className="tenants">properties</div>
                </div>
              </div>
              <hr className="hr2" />
              <div style={{width : "100%", overflowY : "auto", flex : 1}} className="scroller">
                {balanceRenters}
              </div>
            </div>
            <div className="paymentFooter"></div>
          </SidePopupWrapper>
        )}
        {
          isLoading &&  <Loader />
        }
      </div>
    );
  }
}

const BalanceRenter = ({
  address, unit, tenant, tenantEmail, amount, date
}) => {
  return (
    <>
      <div className="grid-container">
        <div className="grid-item">
          <b>{address}</b>
          <span>{unit}</span>
        </div>
        <div className="grid-item">
          <b>{tenant}</b>
          <span className="disabled">{tenantEmail}</span>
        </div>
        <div className="grid-item">
          <b>${amount}</b>
          <span className="disabled">{date}</span>
        </div>
      </div>
      <hr />
    </>
  )
}
export default PaymentDashboard;

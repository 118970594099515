import React, { useContext, useEffect, useState } from 'react'
import "./Setup.css";
import Header from '../../../components/Header/Header';
import Wrapper from '../../../components/wrapper/Wrapper';
import { MdArrowRight, MdClose, MdOutlineEdit, MdSearch } from 'react-icons/md';
import { addressSearch, cacheUser, isLoggedIn, logout } from '../../../utility/util';
import { useNavigate } from 'react-router-dom';
import Button from "../../../components/Button/Button"
import { accountSetup, fetchUserDetails, updateAccountRole } from '../../../services/account.service';
import { isAddressAvailable, createNewProperty, loadRenterDetails, searchPropertyForRenter,raiseDispute, confirmRenterProperty } from '../../../services/property.service';
import { FormException } from '../../../utility/exceptions';
import { getUserRoles, openSupportIssue } from '../../../services/base.service';
import PropertyManagerSetup from '../../../components/account/property_manager/setup/PropertyManagerSetup';
import HighlighterButton from '../../../components/Button/HighlighterButton';
import PopupWrapper from '../../../components/wrapper/PopupWrapper';
import { AppContext } from '../../../App';

function AccountSetup({title = "Account Setup"}) {

  const appContext = useContext(AppContext);

  const [isHelpAndSupportClicked, setHelpAndSupport] = useState(false);

  const [pageLabel, setPageLabel] = useState("");
  const [userData, setUserData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [pager, setPager] = useState(0);
  const [userRoles, setUserRoles] = useState([]);

  const [propertySetupType, setPropertySetupType] = useState("singleUnit");
  const [propertyDetailsList, setPropertyDetailsList] = useState([]);

  const [renterDetails, setRenterDetails] = useState(null);
  const [renterDetailsArray, setRenterDetailsArray] = useState(null);
  const [confirmRenter, setConfirmRenter] = useState(null);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressPlaceID, setSelectedAddressPlaceID] = useState(null);

  const [multiUnitPropertiesFound, setMultiUnitPropertiesFound] = useState(false);
  const [noAddressExist, setNoAddressExist] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);

  const [stepTitle, setStepTitle] = useState("Tell us a bit about yourself")

  const [popupDiv, setPopupDiv] = useState(false);

  const [popupMessage, setPopupMessage] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();


  const performLogout = () => {
    if(logout()){
      navigate("/login")
    }
  }
  
  useEffect(() => {
    document.title = "Account Setup | ando"
    setPageLabel(title)
    const user = isLoggedIn();
    if(!user){
      // navigate("/login")
    }
    else{
      fetchUserDetails(user.source, user.type).then((resp) => {
        if(resp?.data?.first_name)
          setFirstName(resp?.data?.first_name);

        if(resp?.data?.last_name)
          setLastName(resp?.data?.last_name);
        
        if(resp?.data?.email)
          setEmail(resp?.data?.email);
      
        if(resp?.data?.phone_number)
          setPhone(resp?.data?.phone_number);
        
        setUserData(resp.data);
        cacheUser(resp.data)
        if(resp.data.setup_completed.toLowerCase() == "completed")
          return navigate("/dashboard/"+resp.data.role.user_role.replace(" ", "_"))
        let current = parseInt(resp.data.setup_completed.split("Step")[1]) - 1;
        if(current > 0){
          setCurrentStep(current);
          setPager(current);
          switch(current){
            case 1 :
              setStepTitle("Select your account type1");
            break;

            case 2 :
              if(resp.data?.role?.user_role === "Renter"){
                setStepTitle("Find your place");
              }
              else{
                setStepTitle("Enter property details");
              }
            break;
          }
        }
      })
      .catch((err) => {
        navigate("/login")
      })
    }
  }, [title, navigate]);


  const openSupportTicket = async () => {
    let data = document.getElementsByName("helpInputBox").item(0).value;
    if(data.length > 10){
      let status = await openSupportIssue({message: data})
      if(status == 200){
        appContext.toastRef.current.launch("Support Ticket Raised, we'll contact you soon");
      }
      else{
        appContext.toastRef.current.launch("Something went wrong");
      }
      setHelpAndSupport(false);
    }
  }

  const handleChange = (e) => {
    const onlyAlphabetsRegex =/^[a-zA-Z]*$/;
    switch (e.target.name) {
      case "first_name":
        if(!onlyAlphabetsRegex.test(e.target.value)){
          // e.target.value = e.target.value.slice(0, -1)
          setFirstName(e.target.value.slice(0, -1))
        }
        else{
          setFirstName(e.target.value)
        }
      break;
      case "last_name" :
        if(!onlyAlphabetsRegex.test(e.target.value)){
          // e.target.value = e.target.value.slice(0, -1)
          setLastName(e.target.value.slice(0, -1))
        }
        else{
          setLastName(e.target.value)
        }
      break;

      case "email" :        
          setEmail(e.target.value)
      break;

      case "phone" :
        const onlyNumberRegex = /^\d+$/;
        if(
          // !onlyNumberRegex.test(e.target.value)
          false
          ){
          // e.target.value = e.target.value.slice(0, -1);
          setPhone(e.target.value.slice(0, -1));
        }
        else{
          setPhone(e.target.value);
        }
      break;

      default :
      break;
    }

  }

  const handleAccountSetupSubmit = (e) => {
    e.preventDefault();
    let messageDiv = document.getElementsByClassName("message").item(0);

    let data = Object.create(userData);
    data.first_name = e.target.first_name.value;
    data.last_name = e.target.last_name.value;
    data.email = e.target.email.value;
    // data.phone_number =  "+1" + e.target.phone.value;
    data.phone_number =  e.target.phone.value;
    data.setup_completed = "Step2" 
    // + (parseInt(userData.setup_completed.split("Step")[1]) + 1)
    const userID = data["id"];
    delete data["created_at"];
    delete data["updated_at"];
    delete data["password"];
    delete data["account_status"];
    delete data["id"];
    if((data.email.length == 0)){
      return FormException(e, "Please enter the valid email",messageDiv)
    }
    const onlyEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/;
    if(!onlyEmailRegex.test(data.email)){
      return FormException(e, "Please enter the valid email",messageDiv)
    }
    if((data.phone_number.length > 0 && data.phone_number.length < 9 )){
      return FormException(e, "Please enter the valid phone number ",messageDiv)
    }

    messageDiv.classList.remove("error");
    messageDiv.style.display = "none"

    accountSetup(userID, data).then((response) => {
      if(response.status){
        const newProgress = parseInt(response.data.setup_completed.split("Step")[1]) - 1;
        setCurrentStep(newProgress);
        setUserData(response.data)
        cacheUser(response?.data?.data)
      }
      else{
        return FormException(e, response.message, messageDiv)
      }
    }).catch((error) => {
      return FormException(e, error.message, messageDiv)
    })
  }

  useEffect(() => {
    const label = document.getElementsByClassName("setupProgressBar")[0].previousSibling;
    const statusBar = document.getElementsByClassName("setupProgressBar")[0].children;
    for(var i = 0; i <= currentStep && i<statusBar.length; i++ ){
      let c = i
      statusBar.item(c).children.item(0).classList.add("fillUp")
      setTimeout(()=> {
        statusBar.item(c).children.item(0).style.width = "100%"
      }, 650)
    }
    label.textContent = "Step " + (currentStep + 1) + " of " + statusBar.length;
    if(currentStep > 0){
      const accountForm = statusBar.item(currentStep - 1).children
      if(currentStep > 0){
        accountForm.item(0).classList.add("fadeOut");
      }
    }

    setTimeout(() =>{
      setPager(currentStep);
    }, 300)

    if(currentStep === 1){
      setStepTitle("Select your account type");
      userRolesUIButtons();

    }
    if(currentStep === 2 && userData.role.user_role == "Renter"){
      loadRenter();
      setStepTitle("Find your place");
    }
    else if(currentStep === 2){
      setStepTitle("Enter property details");
    }

  }, [currentStep]);

  const userRolesUIButtons = () => {
    let roleUIArray = [];
    getUserRoles().then((resp) => {
      if(resp.status){
        resp.data.forEach((element) => {
          let el = {
            role : element.user_role,
            desc : element.description,
            id : element.id
          }
          roleUIArray.push(el);
        })
      }
    })
    .then(() => {
      setUserRoles(roleUIArray);
    })
  }

  const loadRenter = () => {
    loadRenterDetails(userData.phone_number, userData.email)
    .then((resp) => {
      if(resp.length == 1) {
        let data = resp;
        data[0].units = filterUnitsByEmailOrPhone(resp[0].units, userData.email, userData.phone_number);
        if(data[0].units.length == 1){
          setRenterDetails(data[0])
          setSelectedAddress(data[0].address)
          setSelectedAddressPlaceID(data[0].placeID)
          setMultiUnitPropertiesFound(false);
        }
        else{
          setMultiUnitPropertiesFound(true);
          setRenterDetailsArray(resp)
        }
        setSelectedAddressPlaceID(resp[0].placeID)
        setSelectedAddress(resp[0].address);
      }
      else if(resp.length > 1){
        let tempResp = [];
        resp.forEach((e) => {
          let data = e;
          data.units = filterUnitsByEmailOrPhone(e.units, userData.email, userData.phone_number);
          if(data.units.length > 0){
            tempResp.push(data)
          }
        })
        setRenterDetailsArray(tempResp);
        setMultiUnitPropertiesFound(true);
      }
      else{
        setMultiUnitPropertiesFound(false);
        setRenterDetails(null);
      }
    })
  }

  const updateUserType = (roleID) => {
    let current = parseInt(userData.setup_completed.split("Step")[1])
    let newStep = "Step3" 
    // + (current + 1)
    updateAccountRole(userData.id, {role : roleID, setup_completed : newStep})
    .then((response) =>{
      setUserData(response.data);
      cacheUser(response.data)
      setCurrentStep(currentStep + 1);
    })
  }

  const updatePropertyType = (e) => {
    setPropertySetupType(e.target.value);
    if(e.target.value == "multiUnit"){
      let tempArray = [
        <PropertyManagerSetup listName={"propertyListRegister"} />
      ]
      setPropertyDetailsList([...tempArray]);
    }
  }

  const toggleAccordian = (accordianIndex) => {
    const element = document.getElementsByClassName("accordianBox")[accordianIndex];
    // let accordianTitle = element.getElementsByClassName("accordianTitle")[0]
    let divToActUpon = element.getElementsByClassName("accordianBoxContainer")[0].getElementsByClassName("propertyDiv")[0];
    if(divToActUpon.style.display == "none"){
      divToActUpon.style.display = "block"
    }
    else{
      divToActUpon.style.display = "none"
    }
  }

  const addPropertyUnit = () => {
    let currentPropertyList = propertyDetailsList;
    currentPropertyList.push(<PropertyManagerSetup listName={"propertyListRegister"} defaultClose={true} />)
    setPropertyDetailsList([...currentPropertyList]);
  }

  const accordianViewContainer = (propertyList) => {
    let tempView = [];
    propertyList.forEach((propertyView, index) => {
      tempView.push(
        <div className='accordianBox' key={index} id={"propertyAccordian-" + index}>
          <span className="accordianTitle" onClick={toggleAccordian.bind(this, index)}>
            <MdArrowRight size={20} color='rgba(0,0,0,0.25)' />
            <label>Unit #{index + 1}</label>
          </span>
          <MdClose size={18} color='rgba(0,0,0,0.35)' className='removeUnit' onClick={removePropertyUnit.bind(this, index)} />
          <div className='accordianBoxContainer'>
            {propertyView}
          </div>
        </div>
      )
    })
    return tempView;
  }

  const removePropertyUnit = (index) => {
    let currentPropertyList = propertyDetailsList;
    if(currentPropertyList.length > 1){
      let newPropertyList = currentPropertyList.filter((e, i) => i !== index);
      setPropertyDetailsList([...newPropertyList]);
    }
  }

  const handlePropertySubmit = (e) => {
    e.preventDefault();

    let messageDiv = document.getElementsByClassName("message").item(0);

    if(selectedAddress == null){
      return FormException(null, "Please Select an Address from dropdown", messageDiv)
    }

    const unitLength = propertyDetailsList.length == 0 ? 1 : propertyDetailsList.length ;

    let propertyDetail = {
      userId : isLoggedIn().data.id,
      type : propertySetupType,
      address : selectedAddress,
      placeID : selectedAddressPlaceID,
      units : []
    }
    
    for(let j = 0; j < unitLength; j++){
      let unit = {
        propertyName : (unitLength > 1) ? e.target.propertyName[j].value : e.target.propertyName.value,
        renterOccupied : "false",
        leaseFrom : "",
        leaseTo :  "",
        rentalPrice : (unitLength > 1) ? e.target.rentalPrice[j].value : e.target.rentalPrice.value,
        // partialPayment : (unitLength > 1) ? e.target.partialPayment[j].value : e.target.partialPayment.value,
        partialPayment : false,
        penalty : (unitLength > 1) ? e.target.penalty[j].value : e.target.penalty.value,
        penaltyPrice : (((unitLength > 1) ? e.target.penalty[j].value : e.target.penalty.value)
        == "true" ) ? (unitLength > 1) ? e.target?.penaltyPrice[j]?.value : e.target?.penaltyPrice?.value : "",
        renterDetails : {
          confirmed : false,
          first_name : (unitLength > 1) ? e.target.first_name[j].value : e.target.first_name.value,
          last_name : (unitLength > 1) ? e.target.last_name[j].value : e.target.last_name.value,
          email : (unitLength > 1) ? e.target.email[j].value : e.target.email.value,
          phone : (unitLength > 1) ? e.target.phone[j].value : e.target.phone.value,
        }
      }
      propertyDetail.units.push(unit);
    }
    createNewProperty(propertyDetail).then((resp) => {
      if(resp.status){
        return navigate("/dashboard/"+userData.role.user_role.replace(" ", "_"))
      }
      else{
        return new FormException(e, "Something Went Wrong", messageDiv);
      }
    })
    .catch((error) => {
      return new FormException(e, error.message, messageDiv);
    })
  }

  const handleSearchChange = (e) => {
    let UI_LIST = document.getElementById("mapSearchDataList");
    if(e.target.value.trim().length > 2){
      addressSearch(e.target.value)
      .then((response) => {
        if(response.length > 0){
          UI_LIST.innerHTML = null;
          response.forEach((element) => {
            var li = document.createElement('li');
            let displayAddr = element.formatted_address
            if(!element.formatted_address.includes(element.name)){
              displayAddr = `${element.formatted_address}`
            }
            li.appendChild(document.createTextNode(`${displayAddr}`));
            if(userData?.role?.user_role.toLowerCase() === "renter"){
              li.onclick = handleRenterSearchItemSelect.bind(this, `${element.formatted_address}`, `${element.place_id}`);
            }
            else{
              li.onclick = handlePMSearchItemSelect.bind(this, `${element.formatted_address}`, `${element.place_id}`);
            }
            UI_LIST.appendChild(li)
          })
          UI_LIST.style.display = "block"
        }
        else{
          UI_LIST.style.display = "none";
        }
      })
    }
    else{
      UI_LIST.style.display = "none";
    }
  }

  const handlePMSearchItemSelect = (address, placeID) => {
      setFirstLoad(false)
      isAddressAvailable(placeID)
      .then((resp) => {
        if(resp.length == 0){
          setSelectedAddress(address);
          setSelectedAddressPlaceID(placeID);
        }
        else{
          let e = {
            target :  {
              value : ""
            }
          }
          handleSearchChange(e)
          setSelectedAddress(null);
          setSelectedAddressPlaceID(null);
          let messageDiv = document.getElementsByClassName("message").item(0);
          FormException(null, "Property Already registered from another manager", messageDiv);
        }
      })
      .catch()
    
  }

  const handleRenterSearchItemSelect = (address, placeID) => {

    setFirstLoad(false)
    
    try{
      searchPropertyForRenter(userData.phone_number, userData.email, placeID)
      .then((resp) => {
          if(resp.length > 0){
            let data = resp;
            data[0].units = filterUnitsByEmailOrPhone(resp[0].units, userData.email, userData.phone_number);
            if(data[0].units.length == 1){
              setRenterDetails(data[0])
              setSelectedAddress(data[0].address)
              setSelectedAddressPlaceID(data[0].placeID)
              setMultiUnitPropertiesFound(false);
            }
            else{
              setRenterDetails(null);
              setMultiUnitPropertiesFound(true);
              setRenterDetailsArray(resp)
            }
            setSelectedAddress(address);
            setSelectedAddressPlaceID(placeID)
          }
          else if (resp.statusCode == 500){
            setMultiUnitPropertiesFound(false);
            setRenterDetails(null);
            setNoAddressExist(true)
          }
          else if (resp?.message?.includes("No Address Found")){
            setMultiUnitPropertiesFound(false);
            setRenterDetails(null);
            setNoAddressExist(true)
          }
          else if (resp?.message?.includes("someone")){
            setMultiUnitPropertiesFound(false);
            setRenterDetails(null);
            setNoAddressExist(false);
            setFirstLoad(false)
          }
          else{
            setMultiUnitPropertiesFound(false);
            setRenterDetails(null);
          }
      })
    }
    catch{
      setMultiUnitPropertiesFound(false);
      setRenterDetails(null);
      setNoAddressExist(true)
    }
    let UI_LIST = document.getElementById("mapSearchDataList");
    UI_LIST.style.display = "none";
    
  }

  const editSearchAddress = () => {
    setSelectedAddress(null);
    setSelectedAddressPlaceID(null);
  }

  const filterUnitsByEmailOrPhone = (units, email, phone) =>{
    return units.filter((element) => {
      return (
        element.renterDetails.phone == phone ||
        element.renterDetails.email == email ||
        element.renterDetails.phone == null ||
        element.renterDetails.email == null
      )
    })
  }

  const unitListGenerator = () => {
    let list = [];
    if(renterDetailsArray?.length > 1){
      renterDetailsArray.forEach((e, index)=> {
        let chilList = [];
        e.units.forEach((element) =>{
          chilList.push(<div className='unitContainer' onClick={selectUnitForRenter.bind(this, element.propertyName, index, e?.address, e?.placeID)}>{element.propertyName}</div>)
        })
        list.push(
          <div className='propertyHolder'>
            <div>{e.address}</div>
            <div className='unitList'>{chilList}</div>
          </div>
        )
      })
    }
    else{
      renterDetailsArray[0].units.forEach((element) =>{
        list.push(<div className='unitContainer' onClick={selectUnitForRenter.bind(this, element.propertyName, 0, renterDetailsArray[0]?.address, renterDetailsArray[0]?.placeID)}>{element.propertyName}</div>)
      })
    }

    return list;
  }

  const selectUnitForRenter = (propertyName, index, address = null, placeID = null, element) => {
    if(address){
      setSelectedAddress(address)
      setSelectedAddressPlaceID(placeID)
    }

    let el = document.getElementsByClassName("unitContainer");
    for(let q = 0; q< el.length ; q++){
      el.item(q).style.background="none"
    }
    element.target.style.background = "#e3e58022"

    let unit = renterDetailsArray[index].units.filter((e) => {
      return e.propertyName == propertyName
    })
    let temp = Object.create(renterDetailsArray);
    temp.units = unit;
    temp.address = renterDetailsArray[index].address;
    temp.placeID = renterDetailsArray[index].placeID;
    setRenterDetails(temp);
    setSelectedAddress(renterDetailsArray[index].address)
    setSelectedAddressPlaceID(renterDetailsArray[index].placeID)
    setMultiUnitPropertiesFound(false);
  }

  const submitRenterDetails = (e) => {
    e.preventDefault();

    let messageDiv = document.getElementsByClassName("message").item(0);
    if(selectedAddress === null){
      return FormException(e, "No Address Selected", messageDiv);
    }

    let renterDesc = null;
    let renterSelectedOption = confirmRenter;
    if(renterSelectedOption === null){
      return FormException(e, "Select Confirm or Raise Dispute Option", messageDiv);
    }
    
    if(renterSelectedOption === "dispute"){
      renterDesc = document.getElementById("descRenter").value.trim();
      if(renterDesc.length < 2){
        return FormException(e, "Dispute description required", messageDiv);
      }
      raiseDispute(
        userData.id, 
        renterDesc, 
        renterDetails.placeID, 
        renterDetails.units[0].propertyName
      ).then((resp) => {
        if(resp.status){
          setPopupMessage("Dispute raised successfully, Property Manager will soon connect with you");
          setPopupDiv(true);
        }
      })
      .catch((error) => {
        return FormException(e, "Something Went Wrong", messageDiv);
      })
    }
    else{
      confirmRenterProperty(
        userData.id, 
        renterDetails.placeID, 
        renterDetails.units[0].propertyName
      ).then((res) =>{
        if(res.status){
          setUserData(res.data)
          cacheUser(res.data)
          return navigate("/dashboard/Renter")
        }
      })
    }
  }

  return (
    <main>
        <Header />
        <section className='mainPanelOld'>
            <span>{pageLabel}</span>
            <Wrapper width='90%' maxWidth={2000}>
                <MdClose color='rgba(0,0,0,0.35)' onClick={performLogout} size={20} className='closePopup'/>
                <div className='basicAccountDetails'>
                  <div className="currentAccountActionTitle">{stepTitle}</div>
                  <div className='accountSetupProgressTracker'>
                    <label>Step 1 of 3</label>
                    <div className='setupProgressBar'>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                      <div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                  {
                    pager === 0 &&
                    <form className='accountSetupForm fadeIn' onSubmit={handleAccountSetupSubmit}>
                      <div className='rowFormInput'>
                        <div className='formInput'>
                          <div>First Name</div>
                          <input type='text' name="first_name" onChange={handleChange} value={firstName} required/>
                        </div>
                        <div className='formInput'>
                          <div style={{justifyContent : 'center'}}>Last Name</div>
                          <input type='text' name="last_name"  onChange={handleChange} value={lastName} required/>
                        </div>
                      </div>
                      <div className='columnFormInput'>
                        <div className='formInput'>
                          <div>Email Address</div>
                          <input type='text' name="email"  onChange={handleChange} value={email}/>
                        </div>
                        <div className='formInput'>
                          <div style={{display:"flex", flexDirection : "column", gap:"0px", alignItems:"self-start"}}>
                            Mobile Number
                            <div style={{fontSize:"10px", opacity:0.75}}>(Optional)</div>
                          </div>
                          <input type='text' name="phone"  onChange={handleChange} value={phone}/>
                        </div>
                      </div>
                      <div className='message'>sample text</div>
                      <div className='pageSubmit'>
                        <Button 
                          primary={false}
                          margin='0'
                          paddingVertical='8px'
                          onPressFunction={performLogout}
                          type='button'>Cancel</Button>
                        <Button 
                          primary={true}
                          margin='0'
                          paddingVertical='8px'
                          type='submit'>Next Step</Button>
                      </div>
                    </form>
                  }
                  {                    
                    pager === 1 &&
                    <form className='accountSetupForm fadeIn' onSubmit={null}>
                      <div className='accountTypeContainer'>
                        {
                          userRoles.map((element, i) => {
                            return (
                              <div className={'typeBox ' + (i === 0? "primaryBorder": "highlightBorder")} key={i}>
                                <div className='typeTitleM'>{element.role}</div>
                                <div className='typeDescriptionM'>{element.desc}</div>
                                <div className={'typeSelectButton ' + (i === 0? "primaryBackground": "highlightBackground")} onClick={updateUserType.bind(this, element.id)}>I'm a {element.role}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div className='pageSubmit'>
                        <Button 
                          primary={false}
                          margin='0'
                          paddingVertical='8px'
                          onPressFunction={(e) => {
                            e.preventDefault()
                            setPager(pager - 1)
                            setCurrentStep(currentStep - 1)
                          }}
                          type='submit'>Previous Step</Button>
                        {/* <Button 
                          primary={true}
                          margin='0'
                          paddingVertical='8px'
                          onPressFunction={(e) => {
                            e.preventDefault()
                            if(userData?.role?.user_role?.length > 0)
                              setPager(pager + 1)
                          }}
                          type='submit'>Next Step</Button> */}
                      </div>
                    </form>
                  }
                  {
                    pager === 2 && userData?.role?.user_role.toLowerCase() === "renter" && renterDetails == null && multiUnitPropertiesFound &&
                    <form className='accountSetupForm fadeIn' onSubmit={null}>
                      <div className='columnFormInput'>
                        {
                          selectedAddress == null && 
                          <div className='formInput'>
                            <div className='iconFormInput'>
                              <MdSearch color='#526035' size={25}/>
                              <input type='text' name="mapSearch"  onChange={handleSearchChange} placeholder='Search...' />
                            </div>
                            <ul className='searchDatalist' id='mapSearchDataList'>
                              <li>Option 1</li>
                              <li>Option 2</li>
                              <li>Option 3</li>
                              <li>Option 4</li>
                            </ul>
                          </div>
                        }
                        {
                          selectedAddress != null &&
                          <div className='editAddress'>
                            <div className='titleMessage' style={{margin : 'unset', marginLeft : 40}}>
                            {selectedAddress}
                            </div>
                            <MdOutlineEdit size={25} color='rgba(0,0,0,0.45)' onClick={editSearchAddress}/>
                          </div>
                        }
                      </div>
                      <div className='subMessage'>
                        Multiple Properties/Units found. Select your unit.
                      </div>
                      <div className='multiUnitContainer'>
                        {unitListGenerator()}
                      </div>
                      <div className='pageSubmit'>
                        <Button 
                          primary={false}
                          margin='0'
                          onPressFunction={(e) => {
                            e.preventDefault()
                            setSelectedAddress(null)
                            setFirstLoad(true)
                            setPager(pager - 1)
                            setCurrentStep(currentStep - 1)
                          }}
                          paddingVertical='8px'
                          type='button'>Previous Step</Button>
                        <Button 
                          primary={true}
                          paddingVertical='8px'
                          margin='0'
                          type='button'>Done</Button>
                      </div>
                    </form>
                  }
                  {
                    pager === 2 && userData?.role?.user_role.toLowerCase() === "renter" && renterDetails == null && !multiUnitPropertiesFound &&
                    <form className='accountSetupForm fadeIn' onSubmit={null}>
                      <div className='columnFormInput'>
                        {
                          selectedAddress == null && 
                          <div className='formInput'>
                            <div className='iconFormInput'>
                              <MdSearch color='#526035' size={25}/>
                              <input type='text' name="mapSearch"  onChange={handleSearchChange} placeholder='Search...' />
                            </div>
                            <ul className='searchDatalist' id='mapSearchDataList'>
                              <li>Option 1</li>
                              <li>Option 2</li>
                              <li>Option 3</li>
                              <li>Option 4</li>
                            </ul>
                          </div>
                        }
                        {
                          selectedAddress != null &&
                          <div className='editAddress'>
                            <div className='titleMessage' style={{margin : 'unset', marginLeft : 40}}>
                            {selectedAddress}
                            </div>
                            <MdOutlineEdit size={25} color='rgba(0,0,0,0.45)' onClick={editSearchAddress}/>
                          </div>
                        }
                      </div>
                      {
                        firstLoad &&
                        <div className='titleMessage'>
                          Welcome to Ando - Property Search, you can search your desired property using above search box
                        </div>
                      }
                      {
                        !firstLoad && !noAddressExist &&
                        <div className='titleMessage'>
                          Oops! Looks like this property has already been Claimed. If you believe this wrong , contact us.
                        </div>

                      }
                      {
                        !firstLoad && noAddressExist &&
                        <div className='titleMessage'>
                          Opps! Address is not registered yet.
                        </div>

                      }
                      <div className='pageSubmit'>
                        <Button 
                          primary={false}
                          paddingVertical='8px'
                          margin='0'
                          onPressFunction={(e) => {
                            e.preventDefault()
                            setSelectedAddress(null)
                            setFirstLoad(true)
                            setPager(pager - 1)
                            setCurrentStep(currentStep - 1)
                          }}
                          type='button'>Previous</Button>
                        <Button 
                          primary={true}
                          onPressFunction={() => {setHelpAndSupport(true)}}
                          margin='0'
                          paddingVertical='8px'
                          type='button'>Contact Us</Button>
                      </div>
                    </form>
                  }
                  {
                    pager === 2 && userData?.role?.user_role.toLowerCase() === "renter" && renterDetails != null && !multiUnitPropertiesFound &&
                    <form className='accountSetupForm fadeIn' onSubmit={submitRenterDetails}>
                      {
                          selectedAddress == null && 
                          <div className='formInput'>
                            <div className='iconFormInput'>
                              <MdSearch color='#526035' size={25}/>
                              <input type='text' name="mapSearch"  onChange={handleSearchChange} placeholder='Search...' />
                            </div>
                            <ul className='searchDatalist' id='mapSearchDataList'>
                              <li>Option 1</li>
                              <li>Option 2</li>
                              <li>Option 3</li>
                              <li>Option 4</li>
                            </ul>
                          </div>
                        }
                        {
                          selectedAddress != null &&
                          <div className='editAddress'>
                            <div className='titleMessage' style={{margin : 'unset', marginLeft : 40}}>
                            {selectedAddress}
                            </div>
                            <MdOutlineEdit size={25} color='rgba(0,0,0,0.45)' onClick={editSearchAddress}/>
                          </div>
                        }

                      <div className='message'>sample text</div>
                      <div className='titleBold'>Confirm Property Details : </div>
                      <div className='renterDetails'>
                        <div className='leftPane'>
                          <div className='renterInfo'>
                            <label>Property Name :&nbsp;</label>
                            <span>{renterDetails.units[0].propertyName}</span>
                          </div>
                          <div className='renterInfo'>
                            <label>Rental Price :&nbsp;</label>
                            <span>${renterDetails.units[0].rentalPrice}</span>
                          </div>
                          {/* <div className='renterInfo'>
                            <label>Partial Payments :&nbsp;</label>
                            <span>{renterDetails.units[0].partialPayment == "true"? "Yes" : "No"}</span>
                          </div> */}
                          <div className='renterInfo'>
                            <label>Penatly for late payments :&nbsp;</label>
                            <span>{renterDetails.units[0].penalty  == "true"? "Yes" : "No"}</span>
                          </div>
                          {/* <div className='renterInfo'>
                            <label>Lease Duration :&nbsp;</label>
                            <span>{renterDetails.units[0].leaseFrom} - {renterDetails.units[0].leaseTo}</span>
                          </div> */}
                        </div>
                        <div className='rightPane'>
                          <div className='formInput'>
                            <label className='formRadio'>
                              <input type='radio' name='confirmRenter' value={"confirm"} onClick={() => { setConfirmRenter("confirm")}} />
                              <span>Confirm Details</span>
                            </label>
                            <label className='formRadio'>
                              <input type='radio' name='confirmRenter' value={"dispute"}  onClick={() => { setConfirmRenter("dispute")}} />
                              <span>Dispute Details</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {
                        confirmRenter == "dispute" &&
                        <div className='columnFormInput'>
                          <div className='renterInfo'>
                            <label>Description :&nbsp;</label>
                            <textarea className='input' id='descRenter'></textarea>
                          </div>
                        </div>
                      }
                      <div className='pageSubmit'>
                        <Button 
                          primary={false}
                          margin='0'
                          onPressFunction={(e) => {
                            e.preventDefault()
                            setSelectedAddress(null)
                            setFirstLoad(true)
                            setPager(pager - 1)
                            setCurrentStep(currentStep - 1)
                          }}
                          paddingVertical='8px'
                          type='button'>Previous</Button>
                        <Button
                          primary={true}
                          paddingVertical='8px'
                          margin='0'
                          type='submit'>Done</Button>
                      </div>
                    </form>
                  }
                  {
                    pager === 2 && userData?.role?.user_role.toLowerCase() === "property manager" &&
                    <form className='accountSetupForm fadeIn' onSubmit={handlePropertySubmit} style={{maxWidth : 900}}>
                      <div className='columnFormInput'>
                        {
                          selectedAddress == null && 
                          <div className='formInput'>
                            <div className='iconFormInput'>
                              <MdSearch color='#526035' size={25}/>
                              <input type='text' name="mapSearch"  onChange={handleSearchChange} placeholder='Search...' />
                            </div>
                            <ul className='searchDatalist' id='mapSearchDataList'>
                              <li>Option 1</li>
                              <li>Option 2</li>
                              <li>Option 3</li>
                              <li>Option 4</li>
                            </ul>
                          </div>
                        }
                        {
                          selectedAddress == null &&
                          <div className='message'>SampleText</div>
                        }
                        {
                          selectedAddress != null &&
                          <div className='editAddress'>
                            <div className='titleMessage' style={{margin : 'unset', marginLeft : 40}}>
                              {selectedAddress}
                            </div>
                            <MdOutlineEdit size={25} color='rgba(0,0,0,0.45)' onClick={editSearchAddress}/>
                          </div>
                        }
                        <div className='formInput'>
                          <label className='formRadio'>
                            <input type='radio' name='unitType' required value={"singleUnit"} onChange={updatePropertyType} />
                            <span>Single Unit</span>
                          </label>
                          <label className='formRadio'>
                            <input type='radio' name='unitType' required value={"multiUnit"} onChange={updatePropertyType} />
                            <span>Multi Unit</span>
                          </label>
                        </div>
                      </div>
                      {
                        propertySetupType === 'singleUnit' &&
                        <PropertyManagerSetup listName={"propertyListRegister"}/>
                      }
                      {
                        propertySetupType === 'multiUnit' &&

                        <div className='accordian'>
                          {
                            propertyDetailsList && accordianViewContainer(propertyDetailsList)
                          }
                        </div>
                      }
                      {
                        propertySetupType === 'multiUnit' &&
                        <HighlighterButton
                          width='150px'
                          fontSize='12px'
                          margin='0'
                          paddingVertical='8px'
                          type='button'
                          onPressFunction={addPropertyUnit}
                        >
                          + Add Unit
                        </HighlighterButton>
                      }
                      <div className='pageSubmit'>
                        <Button 
                          primary={false}
                          margin='0'
                          paddingVertical='8px'
                          onPressFunction={(e) => {
                            e.preventDefault()
                            setSelectedAddress(null)
                            setFirstLoad(true)
                            setPager(pager - 1)
                            setCurrentStep(currentStep - 1)
                          }}
                          type='button'>Previous</Button>
                        <Button 
                          primary={true}
                          paddingVertical='8px'
                          margin='0'
                          type='submit'>Done</Button>
                      </div>
                    </form>
                  }
                </div>
                {
                  popupDiv &&
                  <PopupWrapper onClose={() => {setPopupDiv(false)}}>
                    <div className='accountPopupDivMessage'>{popupMessage}</div>
                  </PopupWrapper>
                }
            </Wrapper>
        </section>
        {
          isHelpAndSupportClicked && 
          (
            <PopupWrapper
              onClose={() => {setHelpAndSupport(false)}}
            >
              <div className='helpbox'>
                <div className='helpboxHeader'>
                  <img src={require("../../../assets/help.png")} />
                  <div className='helpTitle'>
                    <p>Need help or support?</p>
                    <span>Send us an email and we'll get back to you within 48 hours.</span>
                  </div>
                </div>
                <textarea name="helpInputBox" placeholder='Insert comments here.'></textarea>
                <Button
                  className='minButton'
                  type='submit'
                  primary={true}
                  onPressFunction={openSupportTicket}
                >Submit</Button>
              </div>
            </PopupWrapper>
          )
        }
    </main>
  )
}

export default AccountSetup
import { redirect } from "react-router-dom";

import { SampleAddress } from "./SampleAddress"
import { GET_ALL_PROPERTIES } from "../services/base.service";

export const isLoggedIn = () => {
    const userData = JSON.parse(sessionStorage.getItem("user_login"))
    return userData?userData:false;
}

export const loginLoader = async () => {
    const user = isLoggedIn();
    if(!user){
      return redirect("/login")
    }
    else{
      return redirect("/dashboard")
    }
}

export const performLogin = (source, type, token) => {
  sessionStorage.setItem("user_login", JSON.stringify({
    source : source,
    type : type
  }))
  sessionStorage.setItem("token", token)
}

export const cacheUser = (userData) => {
  let user = isLoggedIn();
  if(userData?.role){
    user["data"] = userData;
    sessionStorage.setItem("user_login", JSON.stringify(user))
    return user;
  }
  return false;
}

export const getUserDetailsFromGoogle = async (user) => {
  return fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
  {
    method: "GET",
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }

  })
  .then((res) => {
    return res.json()
  })
  .catch((err) => console.log(err));
}

export const logout = () => {
  sessionStorage.clear();
  return true;
}

export const addressSearch = async (keyword) => {
  if(process.env.REACT_APP_ENABLE_REAL_PLACE_SEARCH.toLowerCase().includes("true")){
    let addressObject = await fetch(GET_ALL_PROPERTIES + "/address/search/" + keyword);
    let response = await addressObject.json();
    if(response.status == "OK"){
      return response.results;
    }
    else{
      return [];
    }
  }
  else{
    return SampleAddress.filter((element) => {
      return (
        element.formatted_address.toLowerCase().includes(keyword.toLowerCase()) ||
        element.name.toLowerCase().includes(keyword.toLowerCase())
      );
    })
  }
}

export const delay = (ms) => {
  return new Promise( resolve => setTimeout(resolve, ms) );
}
import React, { useEffect, useState } from "react";
import "./Header.css";

import { MdNotifications } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../../utility/util";
import Profile from "../profile/Profile";
function Header({ showNotification = false, name = "" }) {
  const [abbrName, setAbbrName] = useState("");
  const [profileActivated, setProfileActivated] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const userInfo = isLoggedIn();
    if(userInfo){
        setUserData(userInfo);
    }
    if(name.length > 0){
      let d = name.split(" ");
      setAbbrName(d[0].charAt(0) + "" + d[1].charAt(0))
    }
  }, []);

  const logoClicked = () => {
    navigate("/dashboard")
  }

  return (
    <section className="header">
      <div className="header-row">
        <div className="logo" style={{cursor:'pointer'}} onClick={logoClicked}>ando</div>

        {showNotification && (
          <div className="topbar">
            <a href="#notifCount" className="icon-link">
              <span>
                {" "}
                <MdNotifications color="#A5A5A5" size={30} />  </span>
                <span className="icon-link__badge">12</span>
            </a>
            <div className="ellipse" style={{cursor : "pointer"}} onClick={()=>setProfileActivated(true)}>{abbrName}</div>
          </div>
        )}
      </div>
      {
        profileActivated &&
        <Profile
          userData={userData}
          onClose={() =>{setProfileActivated(false)}}/>
      }
    </section>
  );
}

export default Header;
